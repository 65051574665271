<!-- 报名成功 -->
<template>
  <div class="apply-success-war">
    <div class="apply-success">
      <div class="icon-box">
        <van-icon name="passed"  />
      </div>
    
      <div class="mesage-box">
        <div class="message-text bl">恭喜你,报名成功！</div>
        <div class="message-text">请及时联系指导老师。</div>
      </div>
    </div>
   <div style="background: #f7f8fa; height: 10px;"></div>
   <div class="apply-title">报名信息:</div>
   <div style="padding: 10px;">
    <van-cell 
    v-for="(item, index) in stuArr"
    :key="item.scoreKey"
    :border="index !== stuArr.length - 1"
    :title="item.label" 
    :value="stuInfo[item.scoreKey]"  />
   </div>
  </div>
</template>
<script>
import { Icon } from 'vant';
import { Cell} from 'vant';
import { studentInfo } from '@/api/index.js'
export default {
  components: {
    [Icon.name]: Icon,
    [Cell.name]: Cell,
  },
  data() {
    return {
      stuId: '',
      stuArr: [
        {label: '考生姓名', scoreKey: 'studName'},
        {label: '拼音', scoreKey: 'studPyName'},
        {label: '性别', scoreKey: 'studSexName'},
        {label: '身份证号', scoreKey: 'idCard'},
        {label: '出生日期', scoreKey: 'studBirthday'},
        {label: '国家', scoreKey: 'studCountry'},
        {label: '民族', scoreKey: 'nationName'},
        {label: '专业', scoreKey: 'artMajorName'},
        {label: '级别', scoreKey: 'levelName'},
        {label: '申报专业已通过最高级', scoreKey: 'maxCertLevel'},
        {label: '已通过最高级证书编号', scoreKey: 'maxCertNo'},
        {label: '发证机构', scoreKey: 'maxCertOrgan'},
        {label: '联系人姓名', scoreKey: 'relateName'},
        {label: '与考生关系', scoreKey: 'relateShip'},
        {label: '联系电话', scoreKey: 'phone'},
        {label: '地址', scoreKey: 'address'},
      ],
      stuInfo: {}
    }
  },
  created() {
    this.stuId = this.$route.query.stuId
    this.getDetail()
  },
  methods: {
    getDetail() {
      studentInfo({ id: this.stuId }).then(res => {
        this.stuInfo = res.resultData
        if (res.resultData.studSex === 1) {
          this.stuInfo.studSexName = '男'
        } else if (res.resultData.studSex === 0) {
          this.stuInfo.studSexName = '女'
        }
      }).catch(() => {
      })
    },
  }
}
</script>
<style lang="less" scoped>
.apply-success-war {
  height: 100%;
  .apply-title {
    font-size: 16px;
    color: #333;
    font-weight: bold;
    padding: 10px 15px;
  }
  .apply-success {
    padding-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .icon-box {
    color: #67C23A;
    .van-icon{
      font-size: 8rem;
    }
  }
  .mesage-box {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .message-text {
    text-align: center;
    font-size: 1.3rem;
    // color: #67C23A;
    color: #333;
    line-height: 2.2rem; 
  }
  .bl {
    font-weight: bold;
  }
}
</style>